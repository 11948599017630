<template>
  <div class="container">
    <PublicHeader :totalName="$t('withPass').title" />
    <div class="content">
      <div v-if="!IsCashPassword">
        <van-field
          v-model="Password"
          type="password"
          :label="$t('withPass').xmm"
          :placeholder="$t('withPass').placeholder1"
        />
        <van-field
          v-model="submitPasswork"
          type="password"
          :label="$t('withPass').qrmm"
          :placeholder="$t('withPass').placeholder2"
        />
        <i></i>
      </div>
      <div v-else>
        <van-field
          v-model="oldPassword"
          type="password"
          :label="$t('withPass').jmm"
          :placeholder="$t('withPass').placeholder"
        />
        <van-field
          v-model="Password"
          type="password"
          :label="$t('withPass').xmm"
          :placeholder="$t('withPass').placeholder1"
        />
        <van-field
          v-model="submitPasswork"
          type="password"
          :label="$t('withPass').qrmm"
          :placeholder="$t('withPass').placeholder2"
        />
        <i></i>
      </div>
    </div>
    <van-button
      size="large"
      type="info" color="#8e1473"
      round
      @click="IsCashPassword ? editCash() : cashFirst()"
      >{{ $t('withPass').confirm }}</van-button
    >
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Password: '',
      oldPassword: '',
      submitPasswork: '',
      IsCashPassword: false
    }
  },
  methods: {
    onConfirm(value) {
      this.bankName = value
      this.show = false
    },

    checkPasswork() {
      if (this.IsCashPassword) {
        if (this.oldPassword.length === '' || this.oldPassword.length < 6) {
          this.$toast({
            message: this.$t('withPass').tips,
            position: 'bottom'
          })
          return false
        }
      }
      if (this.Password.length === '' || this.Password.length < 6) {
        this.$toast({
          message: this.$t('withPass').tips1,
          position: 'bottom'
        })
        return false
      }
      if (this.submitPasswork.length === '' || this.submitPasswork.length < 6) {
        this.$toast({
          message: this.$t('withPass').tips2,
          position: 'bottom'
        })
        return false
      }
      if (this.Password !== this.submitPasswork) {
        this.$toast({
          message: this.$t('withPass').tips3,
          position: 'bottom'
        })
        return false
      }
      return true
    },
    async cashFirst() {
      if (!this.checkPasswork()) {
        return
      }
      await userApi.cashFirst({
        password: this.Password
      })
      this.$toast.success(this.$t('withPass').tips4)
      this.$router.go(-1)
    },
    async editCash() {
      if (!this.checkPasswork()) {
        return
      }
      await userApi.editCash({
        newPassword: this.Password,
        oldPassword: this.oldPassword
      })
      this.$toast.success(this.$t('withPass').tips4)
      this.$router.go(-1)
    },
    async getUserInfo() {
      let res = await userApi.userInfo()

      this.IsCashPassword = res.IsCashPassword
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  font-size: 14px
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .content
    padding 10px
    margin 10px
    border-radius 4px
    background #17181e
  .van-field
    background none
    margin 0
    >>> .van-field__label
      color #fff
      margin 0
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    margin 21px 15px 23px
    font-size 14px
    height 44px
</style>
